import { useEffect, useRef } from 'react';

export const useClickOutside = (handler = noop) => {
	const ref = useRef();

	const mouseDownHandler = ({ target }) => {
		const isOutside = ref.current && ref.current.contains(target) === false;
		if (isOutside) {
			handler();
		}
	};

	useEffect(() => {
		window.addEventListener('mousedown', mouseDownHandler);

		return () => {
			window.removeEventListener('mousedown', mouseDownHandler);
		};
	});

	return ref;
};
