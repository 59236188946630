import React from "react";
import { motion } from "framer-motion";

export const CircleUser = (props) => {
    return (
        <motion.svg
            {...props}
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            className="CircleUser"
        >
            <circle cx="12" cy="12" r="10" strokeWidth="2" />
            <circle cx="12" cy="9.5" r="3" strokeWidth="2" />
            <path
                d="M5.28305 19C6.61812 17.1865 8.94161 16 12 16C15.0584 16 17.3819 17.1865 18.7169 19"
                strokeWidth="2"
            />
        </motion.svg>
    );
};
