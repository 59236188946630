import React, { memo, useRef } from "react";
import classnames from "classnames";
import { motion } from "framer-motion";

import { useTranslate } from "app/hooks";
import { useHover } from "ui/hooks";
import { CircleUser } from "ui/components/icons/CircleUser";

export const SignoutButton = memo(
    ({ children: username, className, ...remainingProps }) => {
        const __ = useTranslate();

        const buttonRef = useRef();
        const hovered = useHover(buttonRef);

        const buttonProps = {
            ref: buttonRef,
            type: "button",
            whileHover: "hover",
            className: classnames(
                "SignoutButton",
                { "SignoutButton--showAction": hovered },
                className
            ),
            ...remainingProps,
        };

        return (
            <motion.button {...buttonProps}>
                <span className="SignoutButton-UsernameLabel">
                    {username}
                </span>

                <span className="SignoutButton-ActionLabel">
                    {__("navigation.signout")}
                </span>

                <CircleUser />
            </motion.button>
        );
    }
);
