import React from "react";
import { motion } from "framer-motion";

const sizes = {
    small: 16,
    normal: 24,
    large: 32,
};

export const Delete = ({ size = "normal", ...remainingProps }) => {
    const animProps = {
        variants: {
            hover: { rotate: 90 },
        },
    };

    const newProps = {
        ...remainingProps,
        width: sizes[size],
        height: sizes[size],
        style: { originX: "50%", originY: "50%" },
    };

    return (
        <motion.svg
            {...animProps}
            {...newProps}
            viewBox="0 0 24 24"
            fill="none"
            className="DeleteIcon"
        >
            <path d="M7.7115 6.2968C7.32098 5.90628 6.68781 5.90628 6.29729 6.2968C5.90676 6.68732 5.90676 7.32049 6.29729 7.71101L10.5902 12.0039L6.29802 16.2961C5.90749 16.6866 5.90749 17.3198 6.29802 17.7103C6.68854 18.1008 7.32171 18.1008 7.71223 17.7103L12.0044 13.4181L16.2958 17.7096C16.6864 18.1001 17.3195 18.1001 17.71 17.7096C18.1006 17.319 18.1006 16.6859 17.71 16.2953L13.4186 12.0039L17.7108 7.71175C18.1013 7.32123 18.1013 6.68806 17.7108 6.29754C17.3202 5.90701 16.6871 5.90701 16.2966 6.29754L12.0044 10.5897L7.7115 6.2968Z" />
        </motion.svg>
    );
};
