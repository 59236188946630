import React, { memo } from "react";
import classNames from "classnames";
import { useDispatch } from "react-redux";
import { motion } from "framer-motion";

import { removeAsTeamMember } from "app/store/actions/users";
import { useConfirm, useTranslate } from "app/hooks";
import { useTeam } from "app/hooks/store";
import { Delete } from "ui/components/icons";

export const Team = memo(({ teamId, userId, disabled, ...remainingProps }) => {
    const __ = useTranslate();

    const confirm = useConfirm();
    const dispatch = useDispatch();
    const team = useTeam(teamId);

    const confirmDelete = __("users.remove-from-team", {
        team: team.teamName,
    });

    const handleRemove = async () => {
        const confirmed = await confirm({ description: confirmDelete });
        if (confirmed) {
            dispatch(removeAsTeamMember({ teamId, userId }));
        }
    };

    disabled = disabled || team.isPending;

    const buttonProps = {
        type: "button",
        onClick: handleRemove,
        whileHover: "hover",
        disabled,
        className: classNames("Team", {
            "Team--isPending": team.isPending,
            "Team--disabled": disabled,
        }),
        ...remainingProps,
    };

    return (
        <motion.button {...buttonProps}>
            {team.teamName}
            {!disabled && <Delete size="small" />}
        </motion.button>
    );
});
