import React, { memo } from "react";
import { useLocation, Route, Switch } from "react-router-dom";
import { useTranslate, useTitle } from "app/hooks";

import { CreateNewUser } from "./Users/CreateNewUser";
import { AddTeams } from "./Users/AddTeams";
import { UsersIndex } from "./Users/UsersIndex";

export const Users = memo(() => {
    const __ = useTranslate();

    const location = useLocation();
    const background = location.state && location.state.background;

    useTitle(__("users.title"));

    // prettier-ignore
    return (
        <>
            <Switch location={background || location}>
                <Route path="/manage/users" component={UsersIndex} />
            </Switch>

            <Switch>
                <Route path="/manage/users/create" component={CreateNewUser} />
                <Route path="/manage/users/:users/add-teams" component={AddTeams} />
            </Switch>
        </>
    );
});
