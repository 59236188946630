import React, { cloneElement, Children, useState, useEffect } from "react";
import classnames from "classnames";
import { motion } from "framer-motion";

import { useClickOutside } from "../../hooks";
import { noop } from "../../helpers";

import { VBox } from "../layout";
import { Label, Text, Alert } from "../typo";
import { ChevronDown } from "../icons/ChevronDown";

export const MultiSelect = ({
    label,
    name,
    children: options,
    error,
    onSelect = noop,
    ...remainingProps
}) => {
    const selectRef = useClickOutside(() => setIsOpen(false));

    const [selectedValues, setSelectedValues] = useState([]);
    const [selectedLabels, setSelectedLabels] = useState([]);

    const [isOpen, setIsOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState(error);

    useEffect(() => {
        const values = Children.toArray(options)
            .filter((option) => option.props.selected)
            .map((option) => option.props.value);

        setSelectedValues(values);
    }, [options]);

    useEffect(() => {
        const labels = Children.toArray(options)
            .filter((option) => selectedValues.includes(option.props.value))
            .map((option) => option.props.label);

        setSelectedLabels(labels);
    }, [selectedValues]);

    useEffect(() => {
        setErrorMessage(error);
    }, [error]);

    const handleClick = () => {
        setIsOpen(!isOpen);
        setErrorMessage();
    };

    const handleSelectedValue = (value) => {
        const values = [...selectedValues, value];
        setSelectedValues(values);
        onSelect(values);
    };

    const handleDeselectedValue = (value) => {
        const values = selectedValues.filter(
            (selectedValue) => selectedValue !== value
        );
        setSelectedValues(values);
        onSelect(values);
    };

    const selectProps = {
        ref: selectRef,
        tabIndex: -1,
        className: classnames(
            "MultiSelect",
            { "MultiSelect--isOpen": isOpen },
            { "MultiSelect--hasError": errorMessage }
        ),
        ...remainingProps,
    };

    const animProps = {
        initial: "close",
        animate: isOpen ? "open" : "close",
    };

    const arrowAnimProps = {
        variants: {
            close: { rotate: 0 },
            open: { rotate: 180 },
        },
    };

    const optionsAnimProps = {
        variants: {
            close: { y: -30 },
            open: { y: 0 },
        },
    };

    // prettier-ignore
    return (
        <VBox>
            {label && <Label>{ label }</Label>}

            <motion.div {...selectProps} {...animProps}>
                <div onClick={handleClick} className="MultiSelect-SelectedValues">
                    <Text>{selectedLabels.join(", ")}</Text>
                    <ChevronDown {...arrowAnimProps} />
                </div>

                <motion.div {...optionsAnimProps} className="MultiSelect-Options">
                    {Children.map(options, (option) =>
                        cloneElement(option, {
                            name,
                            onSelect: handleSelectedValue,
                            onDeselect: handleDeselectedValue,
                        })
                    )}
                </motion.div>
            </motion.div>

            {errorMessage && <Alert>{errorMessage}</Alert>}
        </VBox>
    );
};

MultiSelect.Option = ({
    name,
    label,
    selected,
    disabled,
    className,
    onSelect,
    onDeselect,
    ...remainingProps
}) => {
    const [isSelected, setIsSelected] = useState(selected);

    const handleSelect = (event) => {
        setIsSelected(event.target.checked);

        if (event.target.checked) {
            onSelect(event.target.value);
        } else {
            onDeselect(event.target.value);
        }
    };

    const optionProps = {
        className: classnames(
            "MultiSelectOption",
            { "MultiSelectOption--disabled": disabled },
            { "MultiSelectOption--selected": isSelected },
            className
        ),
    };

    const inputProps = {
        name: `${name}[]`,
        onChange: handleSelect,
        checked: isSelected,
        disabled,
        ...remainingProps,
    };

    // prettier-ignore
    return (
        <div {...optionProps}>
            <input type="checkbox" {...inputProps} />
            <div className="MultiSelectOption-Checkbox"></div>
            <Text>{label}</Text>
        </div>
    );
};
