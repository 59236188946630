import React, { memo } from "react";
import classNames from "classnames";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, Link } from "react-router-dom";

import { deleteTeam } from "app/store/actions/teams";

import { useConfirm, useTranslate } from "app/hooks";
import { hasPendingMembers, isFullTeam } from "app/store/selectors/teams";
import { useTeam } from "app/hooks/store";

import { SimpleButton, IconButton } from "ui/components/buttons";
import { Table, Collection } from "ui/components/layout";
import { Text } from "ui/components/typo";
import { Add } from "ui/components/icons";
import { TeamMember } from "./TeamMember";

export const TeamDetails = memo(({ id: teamId }) => {
    const __ = useTranslate();
    
    const confirm = useConfirm();
    const location = useLocation();
    const dispatch = useDispatch();

    const teamDetails = useTeam(teamId);
    const pendingMembers = useSelector((state) => hasPendingMembers(state, teamId));
    const fullTeam = useSelector((state) => isFullTeam(state, teamId));

    const pendingLabel = __("teams.pending");
    const confirmDelete = __("teams.delete-team", { 
        team: teamDetails.teamName,
    });

    const handleDeleteTeam = async () => {
        const confirmed = await confirm({ description: confirmDelete });
        if (confirmed) {
            dispatch(deleteTeam(teamId));
        }
    };

    const pending = teamDetails.teamStatus === 'pending';
    const disabled = pending || pendingMembers;

    const className = classNames("TeamDetails", { 
        "TeamDetails--pending": pending,
        "TeamDetails--disabled": disabled, 
    });

    // prettier-ignore
    return (
        <Table.Row className={className}>
            <td>
                <Text emphasized>{teamDetails.teamName}</Text>
                {(pending || pendingMembers) && (
                    <Text className="TeamDetails-Status">
                        {pendingLabel}
                    </Text>
                )}
            </td>
            <td>
                <Collection>
                    {teamDetails.teamMembers.map((userId) => (
                        <TeamMember key={userId} teamId={teamId} userId={userId} disabled={disabled} />
                    ))}

                    {!disabled && !fullTeam && (
                        <Link to={{ pathname: `/manage/teams/${teamId}/add-members`, state: { background: location }}}>
                            <SimpleButton className='TeamDetails-AddButton'>
                                <Add/>
                            </SimpleButton>
                        </Link>
                    )}
                </Collection>
            </td>
            <td>
                {!disabled && (
                    <IconButton onClick={handleDeleteTeam} icon="circleDelete" iconColor="red" />
                )}
            </td>
        </Table.Row>
    );
});
