import React from 'react';
import { motion } from 'framer-motion';

export const CircleAdd = props => {
	const animProps = {
		variants: {
			hover: { rotate: 90 },
		},
	};

	return (
		<motion.svg
			{...animProps}
			{...props}
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			className="CircleAddIcon"
		>
			<path d="M12 7.5V16.5" strokeWidth="2" strokeLinecap="round" />
			<path d="M16.5 12L7.5 12" strokeWidth="2" strokeLinecap="round" />
			<circle cx="12" cy="12" r="10" strokeWidth="2" />
		</motion.svg>
	);
};
