import { del } from "app/services";
import { REMOVE_AS_TEAM_MEMBER } from "app/constants/actions";

export const removeAsTeamMember = ({userId, teamId}) => async (dispatch) => {
    await del(`/users/${userId}/teams`, {
        teams: [teamId],
    });

    return dispatch({
        type: REMOVE_AS_TEAM_MEMBER,
        data: {
            userId,
            teamIds: [teamId]
        },
    });
};
