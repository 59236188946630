import { useEffect, useState, useCallback } from "react";

export const useHover = (ref) => {
    const [hovered, setHovered] = useState(false);

    const handleMouseOver = useCallback(() => setHovered(true), []);
    const handleMouseOut = useCallback(() => setHovered(false), []);

    useEffect(() => {
        if (ref.current) {
            ref.current.addEventListener("mouseover", handleMouseOver);
            ref.current.addEventListener("mouseout", handleMouseOut);

            return () => {
                ref.current.removeEventListener("mouseover", handleMouseOver);
                ref.current.removeEventListener("mouseout", handleMouseOut);
            };
        }
    }, [ref]);

    return hovered;
};
