import React from 'react';
import styled from 'styled-components';

export const Animation = ({
	playing,
	resetOnUpdate,
	children,
	...remainingProps
}) => {
	const animProps = {
		playState: playing ? 'running' : 'paused',
	};

	if (resetOnUpdate) {
		animProps.key = Date.now();
	}

	return (
		<StyledAnimation {...animProps} {...remainingProps}>
			{children}
		</StyledAnimation>
	);
};

const StyledAnimation = styled.div`
	animation-duration: ${(props) => props.duration};
	animation-timing-function: ${(props) => props.timingFunction};
	animation-delay: ${(props) => props.delay};
	animation-iteration-count: ${(props) => props.iterationCount};
	animation-direction: ${(props) => props.direction};
	animation-fill-mode: ${(props) => props.fillMode};
	animation-play-state: ${(props) => props.playState};
	display: ${(props) => props.display};
`;

StyledAnimation.defaultProps = {
	duration: '1s',
	timingFunction: 'ease',
	delay: '0s',
	iterationCount: '1',
	direction: 'normal',
	fillMode: 'both',
	playState: 'running',
	display: 'block',
};
