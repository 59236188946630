import { post } from "app/services";
import { ADD_TEAM_MEMBERS } from "app/constants/actions";

export const addTeamMembers = ({ teamId, users }) => async (dispatch) => {
    await post(`/teams/${teamId}/members`, { users });

    return dispatch({
        type: ADD_TEAM_MEMBERS,
        data: {
            teamId,
            userIds: users,
        },
    });
};
