import { useRef, useEffect } from "react";
import serialize from "form-serialize";
import { noop } from "ui/helpers";

export const useForm = (submitHandler = noop) => {
    const formRef = useRef();

    const handleSubmit = (event) => {
        event.preventDefault();

        const data = serialize(formRef.current, { disabled: true });
        submitHandler(data);
    };

    useEffect(() => {
        if (formRef.current) {
            formRef.current.addEventListener("submit", handleSubmit);
            return () =>
                formRef.current.removeEventListener("submit", handleSubmit);
        }
    }, [formRef]);

    return formRef;
};
