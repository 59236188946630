import React, { memo } from "react";
import { useLocation, Route, Switch } from "react-router-dom";
import { useTranslate, useTitle } from "app/hooks";

import { TeamsIndex } from "./Teams/TeamsIndex";
import { CreateNewTeam } from "./Teams/CreateNewTeam";
import { AddTeamMembers } from "./Teams/AddTeamMembers";

export const Teams = memo(() => {
    const __ = useTranslate();

    const location = useLocation();
    const background = location.state && location.state.background;

    useTitle(__("teams.title"));

    // prettier-ignore
    return (
        <>
            <Switch location={background || location}>
                <Route path="/manage/teams" component={TeamsIndex} />
            </Switch>

            <Switch>
                <Route path="/manage/teams/create" component={CreateNewTeam} />
                <Route path="/manage/teams/:teamId/add-members" component={AddTeamMembers} />
            </Switch>
        </>
    );
});
