import MarkdownIt from "markdown-it";
import { useCallback } from "react";
import { get } from "object-path";

const md = new MarkdownIt({ breaks: true });
const dictionary = JSON.parse(window.translations.innerHTML);

export const useTranslate = () =>
    useCallback((key, replace = {}) => {
        const phrase = get(dictionary, key);
        const keys = Object.keys(replace);

        if (keys.length === 0) {
            return md.renderInline(phrase);
        }

        const pattern = keys.map((key) => `:${key}`).join("|");
        const regex = new RegExp(pattern, "gi");

        const copy = phrase.replace(regex, (match) => replace[match.substring(1)]);
        return md.renderInline(copy);
    }, []);
