import React from 'react';
import { motion } from 'framer-motion';

export const Edit = props => (
	<motion.svg
		{...props}
		width="24"
		height="24"
		viewBox="0 0 24 24"
		fill="none"
		className="EditIcon"
	>
		<path
			d="M14 6L6 14L5 19L10 18L18 10M14 6L15 5C16.1046 3.89543 17.8954 3.89543 19 5V5C20.1046 6.10457 20.1046 7.89543 19 9L18 10M14 6L18 10"
			strokeWidth="2"
			strokeLinejoin="round"
		/>
	</motion.svg>
);
