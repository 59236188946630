import React, { forwardRef } from 'react';
import classnames from 'classnames';
import { motion } from 'framer-motion';

export const TableRow = forwardRef(
	({ children, canHover = false, className, ...remainingProps }, ref) => {
		const rowProps = {
			// whileHover: 'hover',
			className: classnames(
				'TableRow',
				{
					'TableRow--canHover': canHover,
				},
				className,
			),
			ref,
			...remainingProps,
		};

		return <motion.tr {...rowProps}>{children}</motion.tr>;
	},
);
