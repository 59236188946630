import React from "react";
import { render, unmountComponentAtNode } from "react-dom";

import { useEscKey } from "ui/hooks";
import { ConfirmDialog } from "app/dialogs/ConfirmDialog";

const element = document.createElement("div");
document.body.appendChild(element);

export const useConfirm = () => {
    useEscKey(() => {
        unmountComponentAtNode(element);
    });

    return (props) =>
        new Promise((resolve) => {
            const confirmProps = {
                ...props,
                onConfirm: () => {
                    unmountComponentAtNode(element);
                    resolve(true);
                },
                onDecline: () => {
                    unmountComponentAtNode(element);
                    resolve(false);
                },
            };

            render(<ConfirmDialog {...confirmProps} />, element);
        });
};
