import React from 'react';
import { motion } from 'framer-motion';

export const ArrowLeft = props => {
	const animProps = {
		variants: {
			hover: { x: -6 },
		},
	};

	return (
		<motion.svg
			{...animProps}
			{...props}
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			className="ArrowLeftIcon"
		>
			<path
				d="M17 12H6M6 12L10 16M6 12L10 8"
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</motion.svg>
	);
};
