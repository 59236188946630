import React from 'react';
import classnames from 'classnames';
import { motion } from 'framer-motion';

export const SimpleButton = ({ children, className, ...remainingProps }) => {
	const buttonProps = {
		type: 'button',
		whileHover: 'hover',
		className: classnames('SimpleButton', className),
		...remainingProps,
	};

	return <motion.button {...buttonProps}>{children}</motion.button>;
};
