import React from 'react';
import { motion } from 'framer-motion';

export const Hamburger = props => (
	<motion.svg
		{...props}
		width="24"
		height="24"
		viewBox="0 0 24 24"
		fill="none"
		className="EditIcon"
	>
		<line x1="6" y1="6" x2="18" y2="6" strokeWidth="2" strokeLinecap="round"/>
		<line x1="6" y1="12" x2="18" y2="12" strokeWidth="2" strokeLinecap="round"/>
		<line x1="6" y1="18" x2="18" y2="18" strokeWidth="2" strokeLinecap="round"/>
	</motion.svg>
);
