import Thunk from 'redux-thunk';
import { applyMiddleware, combineReducers, compose, createStore as createReduxStore } from 'redux';

import * as reducers from 'app/store/reducers';

let store;

export const createStore = () => {
    if (!store) {
        const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
        const middleware = composeEnhancers(applyMiddleware(Thunk));

        store = createReduxStore(combineReducers(reducers), middleware);
    }

    return store;
};
