import React, { memo } from "react";
import { useLocation, Link } from "react-router-dom";

import { useTranslate } from "app/hooks";
import { useTeams } from "app/hooks/store";

import { PrimaryButton } from "ui/components/buttons";
import { Table } from "ui/components/layout";
import { TeamDetails } from "./TeamDetails";

export const TeamsIndex = memo(() => {
    const __ = useTranslate();

    const location = useLocation();
    const teams = useTeams();

    // prettier-ignore
    return (
        <section className="TeamsIndex">
            <Table fixed>
                <Table.Header>
                    <th>{__('teams.team')}</th>
                    <th>{__('teams.members')}</th>
                    <th></th>
                </Table.Header>

                <Table.Body>
                    {teams.map((team) => (
                        <TeamDetails key={team.id} id={team.id} />
                    ))}
                </Table.Body>
            </Table>

            <footer>
                <Link to={{ pathname: "/manage/teams/create", state: { background: location }}}>
                    <PrimaryButton>
                        { __('teams.create-team')}
                    </PrimaryButton>
                </Link>
            </footer>
        </section>
    );
});
