import React, { memo, useEffect, useState } from "react";
import classnames from "classnames";
import { motion } from "framer-motion";

import { VBox } from "../layout/VBox";
import { Label } from "../typo/Label";
import { noop } from "../../helpers";

export const Switch = memo(
    ({
        name = "switch",
        label,
        value,
        onToggle = noop,
        disabled,
        className,
    }) => {
        const [isChecked, setIsChecked] = useState(!!value);

        useEffect(() => {
            setIsChecked(value);
        }, [value]);

        const handleClick = (event) => {
            onToggle({ name, value: !isChecked });
            setIsChecked(!isChecked);
        };

        const handleAnimProps = {
            initial: isChecked ? "checked" : "unchecked",
            animate: isChecked ? "checked" : "unchecked",
            variants: {
                unchecked: { x: 2 },
                checked: { x: 20 },
            },
        };

        // prettier-ignore
        return (
            <VBox className={className}>
                {label && <Label>{label}</Label>}
				<input type="hidden" name={name} defaultValue={isChecked} />

                <div onClick={handleClick} className={classnames("Switch", { 
					"Switch--isChecked": isChecked, 
					"Switch--disabled": disabled, 
				})}>
					<div className="Switch-Background"></div>
                    <motion.div {...handleAnimProps} className="Switch-Handle"></motion.div>
                </div>
            </VBox>
        );
    }
);
