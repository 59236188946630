import { post } from "app/services";
import { ADD_AS_TEAM_MEMBER } from "app/constants/actions";

export const addAsTeamMember = ({ userId, teams }) => async (dispatch) => {
    await post(`/users/${userId}/teams`, { teams });

    return dispatch({
        type: ADD_AS_TEAM_MEMBER,
        data: {
            userId,
            teamIds: teams,
        },
    });
};
