import produce from "immer";
import * as Actions from "app/constants/actions";

export const teams = (currentState = {}, { type, data }) =>
	produce(currentState, (draft) => {
		if (type === Actions.SET_TEAMS) {
			Object.entries(data.entities.teams).forEach(([id, team]) => {
				draft[id] = team;
			});
		}
		
		if (type === Actions.CREATE_TEAM) {
			const teamId = data.result;
			draft[teamId] = data.entities.teams[teamId];
		}

		if (type === Actions.ADD_TEAM_MEMBERS) {
			const team = draft[data.teamId];

			team.teamMembers = [
				...team.teamMembers,
				...data.userIds,
			];
		}

		if (type === Actions.REMOVE_TEAM_MEMBERS) {			
			const team = draft[data.teamId];

			team.teamMembers = team.teamMembers.filter((userId) => 
				!data.userIds.includes(userId)
			);
		}

		if (type === Actions.DELETE_TEAM) {
			delete draft[data.teamId];
		}

		if (type === Actions.SET_USERS || type === Actions.SET_USER_INFO) {
			Object.entries(data.entities.teams).forEach(([id, team]) => {
				if (!draft[id]) {
					draft[id] = team;
				}
			});
		}

		if (type === Actions.DELETE_USER) {
			Object.values(draft).forEach((team) => {
				team.teamMembers = team.teamMembers.filter((userId) => 
					userId !== data.userId
				);
			})
		}
	});
