import React from 'react';
import { motion } from 'framer-motion';

export const CircleArrowRight = props => {
	const animProps = {
		variants: {
			hover: { x: 6 },
		},
	};

	return (
		<motion.svg
			{...animProps}
			{...props}
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			className="CircleArrowRightIcon"
		>
			<path
				d="M7 12H16M16 12L12 16M16 12L12 8"
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<circle cx="12" cy="12" r="10" strokeWidth="2" />
		</motion.svg>
	);
};
