import React, { memo, useState } from "react";
import is from "is_js";
import { useDispatch } from "react-redux";

import { useRequest, useTranslate, useTitle } from "app/hooks";
import { signup } from "app/store/actions/auth";
import { useForm } from "ui/hooks/forms";

import { PrimaryButton } from "ui/components/buttons";
import { Form, TextInput, Select } from "ui/components/Form";
import { Alert, Text } from "ui/components/typo";
import { HeadShake } from "ui/components/animations";

export const Signup = memo(() => {
    const __ = useTranslate();

    const dispatch = useDispatch();
    const requestInfo = useRequest();

    useTitle(__("signup.title"));

    const [error, setError] = useState();
    const [success, setSuccess] = useState(false);

    const formRef = useForm(async (data) => {
        try {
            await dispatch(signup(data));
            setSuccess(true);
        } catch (error) {
            const { errors, message } = error.response.data;

            setError(errors || message);
            setSuccess(false);
        }
    });

    // prettier-ignore
    return (
        <section className="Signup">
            <h1>{__('signup.title')}</h1>

            {!success && 
                <Form ref={formRef}>
                    <Text>{__('signup.intro')}</Text>

                    <input type="hidden" name="checkout_id" value={requestInfo.cid}/>
                    
                    <TextInput label={__('signup.firstname')} name="firstname" error={error?.firstname} />
                    <TextInput label={__('signup.lastname')} name="lastname" error={error?.lastname} />
                    <TextInput label={__('signup.email')} name="email" type='email' error={error?.email} />
                    <TextInput label={__('signup.company')} name="companyname" error={error?.companyname} />

                    <Select name="serverlocation" label={__('signup.location')}>
                        <Select.Option value="Amsterdam" selected>Amsterdam</Select.Option>
                        <Select.Option value="San Francisco">San Francisco</Select.Option>
                        <Select.Option value="New York">New York</Select.Option>
                    </Select>

                    {is.string(error) && <Alert>{error}</Alert>}

                    <HeadShake resetOnUpdate={true} playing={error} style={{width: '100%'}}>
                        <PrimaryButton type='submit' className='SubmitButton'>{__('signup.submit')}</PrimaryButton>
                    </HeadShake>
                </Form>
            }

            {success && (
                <Text>{__('signup.success')}</Text>
            )}
        </section>
    );
});
