import React from 'react';
import classnames from 'classnames';
import styled from 'styled-components';

export const VBox = ({ children, className, ...remainingProps }) => (
	<StyledVBox {...remainingProps} className={classnames('VBox', className)}>
		{children}
	</StyledVBox>
);

const StyledVBox = styled.div`
	display: flex;
	flex-direction: column;
`;
