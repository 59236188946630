import camelcaseKeys from "camelcase-keys";
import { normalize, schema } from "normalizr";

import { get } from "app/services";
import { SET_TEAMS } from "app/constants/actions";

const userSchema = new schema.Entity("users", {
    //
});

const teamSchema = new schema.Entity("teams", {
    teamMembers: [userSchema],
});

export const loadTeams = () => async (dispatch) => {
    let { data } = await get("/teams");

    data = camelcaseKeys(data, { deep: true });
    data = normalize(data, [teamSchema]);

    dispatch({
        type: SET_TEAMS,
        data,
    });
};
