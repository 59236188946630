import React from 'react';
import styled, { css } from 'styled-components';
import classnames from 'classnames';

export const Grid = ({
	rows = 2,
	columns = 2,
	children,
	className,
	...remainingProps
}) => {
	const gridProps = {
		className: classnames('Grid', className),
		rows,
		columns,
		...remainingProps,
	};

	return <StyledGrid {...gridProps}>{children}</StyledGrid>;
};

const StyledGrid = styled.div`
	${(props) => css`
		display: grid;
		grid-template-rows: repeat(${props.rows}, 1fr);
		grid-template-columns: repeat(${props.columns}, 1fr);
		row-gap: 14px;
		column-gap: 20px;
	`}
`;
