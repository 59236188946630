import { createStore } from './createStore';

let store;

export const getStore = () => {
	if (store) {
		return store;
	}

	store = createStore();

	return store;
};
