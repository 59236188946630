import React, { createContext, useState, useCallback } from 'react';
// import usePortal from 'react-useportal';
import uid from 'uid';
import is from 'is_js';

import { Alert } from './alerts/Alert';

export const NotificationContext = createContext();
export const SUCCESS = 'success';
export const INFO = 'info';
export const WARNING = 'warning';
export const ERROR = 'error';

const createAlert = (message, type = INFO) => ({
	id: uid(),
	type,
	message,
});

export const Alerts = ({ children }) => {
	const [alerts, setAlerts] = useState([]);

	// const { Portal } = usePortal({
	// 	bindTo: document.getElementById('alerts'),
	// });

	const addAlert = useCallback((message) => {
		setAlerts([...alerts, createAlert(message)]);
	}, []);

	const contextValue = {
		alert: addAlert,
		alerts,
	};

	return (
		<NotificationContext.Provider value={contextValue}>
			{children}
			{/* {is.not.empty(alerts) && (
				<Portal>
					{alerts.map(({ id, ...alertProps }) => (
						<Alert key={id} {...alertProps} />
					))}
				</Portal>
			)} */}
		</NotificationContext.Provider>
	);
};
