import React, { forwardRef, useState, useEffect, useCallback } from 'react';
import classnames from 'classnames';

import { Label, Alert } from '../typo';
import { VBox } from '../layout';
import { Search } from '../icons/Search';

export const TextInput = forwardRef(
	({ className, name, value, label, error, icon, onChange, ...remainingProps }, ref) => {
		const [errorMessage, setErrorMessage] = useState(error);

		useEffect(() => {
			setErrorMessage(error);
		}, [error]);

		const handleChange = (event) => {
			setErrorMessage();

			if (typeof onChange === 'function') {
				onChange(event);
			}
		};

		const inputProps = {
			type: 'text',
			name: name || 'unnamed-input',
			autoComplete: `hedge-${name}`,
			defaultValue: value,
			className: classnames('TextInput', { 'TextInput--hasError': errorMessage }, className),
			onChange: handleChange,
			ref,
			...remainingProps,
		};


		const renderIcon = useCallback(() => {
			switch (icon) {
				case 'search':
					return <Search />;
				}
		}, [icon]);

		return (
			<VBox>
				{label && <Label>{label}</Label>}
				
				<input {...inputProps} />
				{errorMessage && <Alert>{errorMessage}</Alert>}
				{renderIcon()}
			</VBox>
		);
	},
);
