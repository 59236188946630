import React from 'react';

// prettier-ignore
export const Spinner = () => (
    <svg width="20" height="20" viewBox="0 0 40 40" xmlns="http://www.w3.org/2000/svg" stroke="#fff">
        <g transform="translate(2 2)" strokeWidth="4" fill="none" fillRule="evenodd">
            <circle strokeOpacity=".5" cx="18" cy="18" r="18"/>
            <path d="M36 18c0-9.94-8.06-18-18-18">
                <animateTransform
                    attributeName="transform"
                    type="rotate"
                    from="0 18 18"
                    to="360 18 18"
                    dur="1s"
                    repeatCount="indefinite"/>
            </path>
        </g>
    </svg>
);
