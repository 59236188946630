import React, { memo, forwardRef } from "react";

import { Modal, Panel } from "ui/components/layout";
import { Text } from "ui/components/typo";
import { PrimaryButton, SimpleButton } from "ui/components/buttons";

// prettier-ignore
export const ConfirmDialog = ({
    title = "Are you sure?",
    description = "",
    ok = "Ok",
    cancel = "Cancel",
    onConfirm,
    onDecline,
    ...remainingProps
}) => (
    <Modal>
        <Panel {...remainingProps} className="ConfirmDialog">
            <h1>{title}</h1>
            <Text>{description}</Text>

            <footer>
                <PrimaryButton onClick={onConfirm}>{ok}</PrimaryButton>
                <SimpleButton onClick={onDecline}>{cancel}</SimpleButton>
            </footer>
        </Panel>
    </Modal>
);
