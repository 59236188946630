import camelcaseKeys from "camelcase-keys";
import { normalize } from "normalizr";

import { post } from "app/services";
import { teamSchema } from "app/store/schemas";
import { CREATE_TEAM } from "app/constants/actions";

export const createNewTeam = (postData) => async (dispatch) => {
    let { data } = await post(`/teams`, postData);
    data = camelcaseKeys(data, { deep: true });
    console.log(data);
    data = normalize(data, teamSchema);

    return dispatch({
        type: CREATE_TEAM,
        data,
    });
};
