import React from "react";
import styled, { css } from "styled-components";
import classnames from "classnames";

import { TableHeader } from "./table/TableHeader";
import { TableBody } from "./table/TableBody";
import { TableRow } from "./table/TableRow";
import { TableFooter } from "./table/TableFooter";

export * from "./table/TableHeader";
export * from "./table/TableBody";
export * from "./table/TableRow";
export * from "./table/TableFooter";

export const Table = ({
    children,
    className,
    fixed = false,
    inline = false,
    ...remainingProps
}) => {
    const tableProps = {
        fixed,
        className: classnames(className, "Table", {
            "Table--inline": inline,
        }),
        ...remainingProps,
    };

    return (
        <StyledTable {...tableProps} {...remainingProps}>
            {children}
        </StyledTable>
    );
};

Table.Header = TableHeader;
Table.Body = TableBody;
Table.Row = TableRow;
Table.Footer = TableFooter;

const StyledTable = styled.table`
    ${(props) =>
        props.fixed &&
        css`
            table-layout: fixed;
        `};
`;
