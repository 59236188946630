import React from 'react';
import { useHistory } from 'react-router-dom';

import { ArrowLeft } from '../icons/ArrowLeft';
import { SimpleButton } from './SimpleButton';

export const BackButton = ({ children, ...remainingProps }) => {
	const history = useHistory();

	const handleClick = () => {
		history.goBack();
	};

	const buttonProps = {
		onClick: handleClick,
		className: 'BackButton',
		...remainingProps
	};

	return (
		<SimpleButton {...buttonProps}>
			<ArrowLeft />
			{children}
		</SimpleButton>
	);
};
