import React, { memo, useRef } from "react";
import { Route, Switch, Redirect } from "react-router-dom";

import { Panel } from "ui/components/layout";
import { Login } from "./App/Auth/Login";
import { Signup } from "./App/Auth/Signup";
import { RequestPassword } from "./App/Auth/RequestPassword";
import { ResetPassword } from "./App/Auth/ResetPassword";
import { Manage } from "./App/Manage";

export const App = memo(() => {
    const contentRef = useRef();

    // prettier-ignore
    return (
        <Panel role="application" className="App">
            <div ref={contentRef}>
                <Switch>
                    {/* Home routes */}
                    <Route exact path="/">
                        <Redirect to="/manage" />
                    </Route>

                    {/* Auth routes */}
                    <Route exact path="/signup" component={Signup} />
                    <Route exact path="/login" component={Login} />
                    <Route exact path="/password" component={RequestPassword} />
                    <Route exact path="/password/reset" component={ResetPassword} />

                    {/* Manage routes */}
                    <Route path="/manage" component={Manage} />
                </Switch>
            </div>
        </Panel>
    );
});
