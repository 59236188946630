import React, { useRef, useState, useEffect } from 'react';
import styled, { css } from 'styled-components';

export const TextArea = ({ editable = false, value, ...remainingProps }) => {
	const inputRef = useRef();
	const [height, setHeight] = useState(0);

	useEffect(() => {
		if (inputRef.current) {
			setHeight(inputRef.current.scrollHeight);
		}
	}, [inputRef, value, height]);

	const handleChange = () => {
		setHeight(0);
	};

	const textProps = {
		ref: inputRef,
		onChange: handleChange,
		className: 'TextArea',
		height,
		value,
		...remainingProps,
	};

	return <StyledTextArea {...textProps}></StyledTextArea>;
};

export const StyledTextArea = styled.textarea`
	${(props) => css`
		height: ${props.height}px;
	`}
`;
