import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { loadTeams } from "app/store/actions/teams";
import { getTeams } from "app/store/selectors/teams";

export const useTeams = () => {
    const dispatch = useDispatch();
    const teams = useSelector((state) => getTeams(state));

    useEffect(() => {
        dispatch(loadTeams());
    }, []);

    return teams;
};
