import { del } from "app/services";
import { DELETE_USER } from "app/constants/actions";

export const deleteUser = (userId) => async (dispatch) => {
    await del(`/users/${userId}`);

    return dispatch({
        type: DELETE_USER,
        data: {
            userId,
        },
    });
};
