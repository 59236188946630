import React, { cloneElement, useRef, useState } from 'react';
import classnames from 'classnames';
import useMeasure from 'use-measure';

import { motion } from 'framer-motion';
import { ChevronDown } from '../icons/ChevronDown';

export const Collapsible = ({ label = 'Open', className, children, ...remainingProps }) => {
	const contentRef = useRef();
	const size = useMeasure(contentRef);

	const [isTransitioning, setIsTransitioning] = useState(false);
	const [isOpen, setIsOpen] = useState(false);

	const handleClick = () => {
		setIsTransitioning(true);
		setIsOpen(!isOpen);
	};

	const handleTransitionComplete = () => {
		setIsTransitioning(false);
	};

	const panelProps = {
		className: classnames('Collapsible', {
			'Collapsible--isOpen': isOpen,
			'Collapsible--isTransitioning': isTransitioning,
			className,
		}),
		...remainingProps,
	};

	const animProps = {
		initial: 'close',
		animate: isOpen ? 'open' : 'close',
		onAnimationComplete: handleTransitionComplete,
	};

	const contentAnimProps = {
		transition: {
			ease: 'easeOut',
			duration: isOpen ? 0.3 : 0.25,
		},
		animate: { height: isOpen ? size?.height : 0 },
		initial: false,
	};

	const arrowAnimProps = {
		variants: {
			close: { rotate: 0 },
			open: { rotate: 180 },
		},
	};

	return (
		<motion.div {...panelProps} {...animProps}>
			<header onClick={handleClick} className="Collapsible-Header">
				<span>{label}</span>
				<ChevronDown {...arrowAnimProps} />
			</header>

			<motion.div {...contentAnimProps} className="Collapsible-Content">
				<div ref={contentRef}>{cloneElement(children, { isOpen })}</div>
			</motion.div>
		</motion.div>
	);
};
