import React, { memo, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import { useTranslate } from "app/hooks";
import { useTeams } from "app/hooks/store";
import { createNewUser } from "app/store/actions/users";

import { useForm } from "ui/hooks/forms";
import { Modal, Panel } from "ui/components/layout";
import { PrimaryButton, SimpleButton } from "ui/components/buttons";
import { Form, TextInput, Select } from "ui/components/Form";
import { useRef } from "react";

export const CreateNewUser = memo(() => {
    const __ = useTranslate();

    const dispatch = useDispatch();
    const history = useHistory();
    const firstNameRef = useRef();

    const [error, setError] = useState();
    const [userState, setUserState] = useState("existing_user");

    const teams = useTeams().filter((team) => team.teamStatus !== "pending");
    const formRef = useForm(async (data) => {
        try {
            await dispatch(createNewUser(data));
            history.goBack();
        } catch (error) {
            const { status, data } = error.response;

            if (status === 404) {
                setUserState("new_user");
                firstNameRef.current.focus();
            } else {
                setError(data.errors || data.message);
            }
        }
    });

    const handleClose = () => {
        history.goBack();
    };

    const title = __("users.create-user-modal.title");
    const firstNameLabel = __("users.create-user-modal.firstname");
    const lastNameLabel = __("users.create-user-modal.lastname");
    const emailLabel = __("users.create-user-modal.email");
    const submitLabel = __("users.create-user-modal.submit");
    const cancelLabel = __("users.create-user-modal.cancel");

    // prettier-ignore
    return (
        <Modal onClose={handleClose}>
            <Panel className="CreateNewUser">
                <header>
                    <h1>{title}</h1>
                </header>

                <Form id='createNewUser' ref={formRef}>
                    <input type="hidden" name="user_type" value={userState} />
                    <TextInput type={emailLabel} label="Email" name="user_email" error={error?.user_email} />
                    
                    {userState === 'new_user' && (
                        <>
                            <TextInput ref={firstNameRef} label={firstNameLabel} name="first_name" error={error?.first_name} />
                            <TextInput label={lastNameLabel} name="last_name" error={error?.last_name} />
                        </>
                    )}

                    <Select label='Team' name='teamId'>
                        {teams.map(({ id, teamName }, index) => (
                            <Select.Option key={id} value={id} selected={index === 0}>{teamName}</Select.Option>
                        ))}
                    </Select>

                    {userState === 'new_user' && (
                        <Select label='Role' name='role'>
                            <Select.Option value='owner' selected>Manager</Select.Option>
                            <Select.Option value='editor'>Editor</Select.Option>
                        </Select>
                    )}
                </Form>

                <footer>
                    <PrimaryButton type="submit" form="createNewUser" className="SubmitButton">
                        {submitLabel}
                    </PrimaryButton>
                    
                    <SimpleButton onClick={handleClose}>
                        {cancelLabel}
                    </SimpleButton>
                </footer>
            </Panel>
        </Modal>
    );
});
