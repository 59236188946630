import React, { cloneElement, useEffect } from "react";
import styled, { keyframes } from "styled-components";

import { useClickOutside, useEscKey } from "ui/hooks";
import { noop } from "ui/helpers";

export const Modal = ({ children, onClose = noop }) => {
    useEffect(() => {
        document.body.style.overflow = "hidden";
        return () => (document.body.style.overflow = "");
    }, []);

    // const contentRef = useClickOutside(onClose);
    useEscKey(onClose);

    return (
        <StyledModal className="Modal">
            {cloneElement(children, { onClose })}
        </StyledModal>
    );
};

// prettier-ignore
const presentModal = keyframes`
	to {
		opacity: 1;
	}
`;

const presentDialog = keyframes`
	to {
		transform: none;
	}
`;

const StyledModal = styled.div`
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 10px;
    min-width: 320px;
    text-rendering: optimizeLegibility;

    @media screen {
        @media (min-width: 0px) {
            display: flex;
        }
    }

    background-color: rgba(0, 0, 0, 0.6);
    opacity: 0.01;
    animation: ${presentModal} 200ms forwards;

    overflow-x: hidden;
    overflow-y: scroll;
    backface-visibility: hidden;
    z-index: 2147483647;

    -webkit-text-size-adjust: none;
    -webkit-overflow-scrolling: touch;

    &:focus {
        outline: none;
    }

    &,
    *,
    *:before,
    *:after {
        box-sizing: border-box;
    }

    & > * {
        margin: auto;
        transform: translate3d(0, 150px, 0);
        backface-visibility: hidden;

        animation: ${presentDialog} 450ms
            cubic-bezier(0.175, 0.885, 0.32, 1.275) forwards;
        cursor: default;
    }
`;
