import React, { memo, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";

import { useTranslate } from "app/hooks";
import { useTeams, useUserInfo } from "app/hooks/store";
import { useForm } from "ui/hooks/forms";

import { addAsTeamMember } from "app/store/actions/users";

import { Modal, Panel } from "ui/components/layout";
import { PrimaryButton, SimpleButton } from "ui/components/buttons";
import { Form, MultiSelect } from "ui/components/Form";

export const AddTeams = memo(() => {
    const __ = useTranslate();
    
    const dispatch = useDispatch();
    const history = useHistory();
    const params = useParams();

    const [error, setError] = useState();

    const user = useUserInfo(params.users);
    const teams = useTeams()
        .filter((team) => !user.memberOfTeams.includes(team.id))
        .filter((team) => team.teamStatus !== "pending");

    const formRef = useForm(async (data) => {
        try {
            await dispatch(addAsTeamMember(data));
            history.goBack();
        } catch (error) {
            const { errors, message } = error.response.data;
            setError(errors || message);
        }
    });

    const handleClose = () => {
        history.goBack();
    };

    // prettier-ignore
    return (
        <Modal onClose={handleClose}>
            <Panel className="AddTeams">
                <header>
                    <h1>
                        {__("users.add-teams.title", { user: user?.fullName })}
                    </h1>
                </header>

                <Form id='addTeams' ref={formRef}>
                    <input type="hidden" name="userId" value={params.users} />

                    <MultiSelect name='teams' error={error?.teams}>
                        {teams.map(({ id, teamName }) => (
                            <MultiSelect.Option key={id} value={id} label={teamName} />
                        ))}
                    </MultiSelect>
                </Form>

                <footer>
                    <PrimaryButton type="submit" form="addTeams" className="SubmitButton">
                        {__("users.add-teams.submit")}
                    </PrimaryButton>
                    
                    <SimpleButton onClick={handleClose}>
                        {__("users.add-teams.cancel")}
                    </SimpleButton>
                </footer>
            </Panel>
        </Modal>
    );
});
