import React, { forwardRef } from 'react';
import classnames from 'classnames';

import { motion } from 'framer-motion';

export const Panel = forwardRef(
	({ children, className, ...remainingProps }, ref) => {
		const panelProps = {
			className: classnames('Panel', className),
			ref,
			...remainingProps,
		};

		return <motion.div {...panelProps}>{children}</motion.div>;
	},
);
