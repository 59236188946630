import React, { memo } from "react";
import { Route, Switch, Redirect } from "react-router-dom";

import { Teams } from "./Manage/Teams";
import { Header } from "./Manage/Header";
import { Users } from "./Manage/Users";

export const Manage = memo(() => (
    <>
        <Header />

        <Switch>
            <Route exact path="/manage">
                <Redirect to="/manage/users" />
            </Route>

            <Route path="/manage/users" component={Users} />
            <Route path="/manage/teams" component={Teams} />
        </Switch>
    </>
));
