import React from 'react';
import { motion } from 'framer-motion';

export const Print = props => (
	<motion.svg
		{...props}
		width="24"
		height="24"
		viewBox="0 0 24 24"
		fill="none"
		className="PrintIcon"
	>
		<rect
			x="8"
			y="16"
			width="8"
			height="3"
			stroke="#1A90FC"
			strokeWidth="2"
			strokeLinejoin="round"
		/>
		<path
			d="M16 9V6C16 5.44772 15.5523 5 15 5H9C8.44772 5 8 5.44772 8 6V9"
			stroke="#1A90FC"
			strokeWidth="2"
			strokeLinejoin="round"
		/>
		<path
			d="M8 17H5C4.44772 17 4 16.5523 4 16V12C4 10.8954 4.89543 10 6 10H18C19.1046 10 20 10.8954 20 12V16C20 16.5523 19.5523 17 19 17H16"
			stroke="#1A90FC"
			strokeWidth="2"
			strokeLinejoin="round"
		/>
		<circle cx="17" cy="13" r="0.5" stroke="#1A90FC" />
	</motion.svg>
);
