import camelcaseKeys from "camelcase-keys";

import { del } from "app/services";
import { REMOVE_TEAM_MEMBERS } from "app/constants/actions";

export const removeTeamMember = ({ teamId, userId }) => async (dispatch) => {
    await del(`/teams/${teamId}/members`, {
        users: [userId],
    });

    return dispatch({
        type: REMOVE_TEAM_MEMBERS,
        data: {
            teamId,
            userIds: [userId],
        },
    });
};
