import React, { forwardRef } from "react";
import classnames from "classnames";

export * from "./form/MultiSelect";
export * from "./form/Select";
export * from "./form/Switch";
export * from "./form/TextArea";
export * from "./form/TextInput";

export const Form = forwardRef(
    ({ children, className, action, onError, ...remainingProps }, ref) => {
        const formProps = {
            className: classnames("Form", className),
            noValidate: "novalidate",
            ref,
            ...remainingProps,
        };

        return <form {...formProps}>{children}</form>;
    }
);
