import { del } from "app/services";
import { DELETE_TEAM } from "app/constants/actions";

export const deleteTeam = (teamId) => async (dispatch) => {
    await del(`/teams/${teamId}`);

    return dispatch({
        type: DELETE_TEAM,
        data: { 
            teamId
        },
    });
};
