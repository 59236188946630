import { del } from "app/services";
import { DISABLE_REMOTE_LOCKING } from "app/constants/actions";

export const disableRemoteLocking = (userId) => async (dispatch) => {
    await del(`/users/${userId}/remotelocking`);

    dispatch({
        type: DISABLE_REMOTE_LOCKING,
        data: {
            userId,
        },
    });
};
