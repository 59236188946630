import React from "react";
import { motion } from "framer-motion";

export const Add = ({ size = "normal", ...remainingProps }) => {
    const animProps = {
        variants: {
            hover: { rotate: 90 },
        },
    };

    const newProps = {
        width: size === "normal" ? 24 : 16,
        height: size === "normal" ? 24 : 16,
        ...remainingProps,
    };

    return (
        <motion.svg
            {...animProps}
            {...newProps}
            viewBox="0 0 24 24"
            className="AddIcon"
        >
            <path d="M12 6V18" strokeWidth="2" strokeLinecap="round" />
            <path d="M18 12L6 12" strokeWidth="2" strokeLinecap="round" />
        </motion.svg>
    );
};
