import React from 'react';
import classnames from 'classnames';

export const SecondaryButton = ({ children, className, ...remainingProps }) => {
	const buttonProps = {
		type: 'button',
		whileHover: 'hover',
		className: classnames('SecondaryButton', className),
		...remainingProps,
	};

	return <button {...buttonProps}>{children}</button>;
};
