import React from "react";
import { render } from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";

import { createStore } from "app/store";
import { loadCurrentUser } from "app/store/actions/users";
import { App } from "app/views/App";

(async () => {
    const store = createStore();

    try {
        /**
         * Before anything try to load the userinfo. If the user is not signed in,
         * he or she is automatically redirected to /login by Laravel. So authentication
         * is done on serverside.
         *
         * We wrap everthing in a try/catch because it's possible to get a
         * 403 - Forbidden response from the server.
         */
        await store.dispatch(loadCurrentUser());
    } finally {
        render(
            <Provider store={store}>
                <Router>
                    <App />
                </Router>
            </Provider>,
            document.getElementById("app")
        );
    }
})();
