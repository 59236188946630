import React, { memo } from "react";
import classNames from "classnames";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, Link } from "react-router-dom";

import { deleteUser, enableRemoteLocking, disableRemoteLocking } from "app/store/actions/users";
import { hasPendingTeams, isMemberOfAllTeams } from "app/store/selectors/users";
import { useConfirm, useTranslate } from "app/hooks";
import { useCurrentUser, useUserInfo } from "app/hooks/store";

import { SimpleButton, IconButton } from "ui/components/buttons";
import { Switch } from "ui/components/Form";
import { Table, Collection } from "ui/components/layout";
import { Text } from "ui/components/typo";
import { Add } from "ui/components/icons";
import { Team } from "./Team";

export const UserDetails = memo(({ id: userId }) => {
    const __ = useTranslate();

    const confirm = useConfirm();
    const location = useLocation();
    const dispatch = useDispatch();
    
    const currentUser = useCurrentUser();
    const userInfo = useUserInfo(userId);
    
    const pendingTeams = useSelector((state) => hasPendingTeams(state, userId));
    const memberOfAllTeams = useSelector((state) => isMemberOfAllTeams(state, userId));
    
    const handleRemoteLockingToggle = async ({ value }) => {
        if (value) {
            dispatch(enableRemoteLocking(userId));
        } else {
            dispatch(disableRemoteLocking(userId));
        }
    };

    const confirmDelete = __("users.delete-user", { user: userInfo.fullName });

    const handleDeleteUser = async () => {
        const confirmed = await confirm({ description: confirmDelete });
        if (confirmed) {
            dispatch(deleteUser(userId));
        }
    };

    const isCurrentUser = currentUser.id === userId;
    const pending = userInfo.userStatus === 'pending' || pendingTeams;
    const disabled = pending || pendingTeams;

    const className = classNames("UserDetails", { 
        "UserDetails--isCurrentUser": isCurrentUser ,
        "UserDetails--pending": pending,
        "UserDetails--disabled": disabled, 
    });

    // prettier-ignore
    return (
        <Table.Row className={className}>
            <td>
                <Text emphasized>
                    {isCurrentUser ? `${userInfo.fullName} (You)` : userInfo.fullName} 
                </Text>

                {pending && (
                    <Text className="UserDetails-Status">
                        (Pending)
                    </Text>
                )}
            </td>
            <td>
                <Text>{userInfo.userEmail}</Text>
            </td>
            {currentUser.allowRemoteLocking && (
                <td>
                    <Switch name="remote_locking_enabled" value={userInfo.remoteLockingEnabled} onToggle={handleRemoteLockingToggle} disabled={disabled} />
                </td>   
            )}
            <td>
                <Collection>
                    {userInfo.memberOfTeams?.map((teamId) => (
                        <Team key={teamId} userId={userId} teamId={teamId} disabled={disabled} />
                    ))}

                    {!disabled && !memberOfAllTeams && (
                        <Link to={{ pathname: `/manage/users/${userId}/add-teams`, state: { background: location }}}>
                            <SimpleButton className='UserDetails-AddButton'>
                                <Add/>
                            </SimpleButton>
                        </Link>
                    )}
                </Collection>
            </td>
            <td>
                {!disabled && !isCurrentUser && (
                    <IconButton onClick={handleDeleteUser} icon="circleDelete" iconColor="red" />
                )}
            </td>
        </Table.Row>
    );
});
