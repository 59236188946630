import camelcaseKeys from "camelcase-keys";
import { normalize, schema } from "normalizr";

import { get } from "app/services";
import { SET_USER_INFO } from "app/constants/actions";

const teamSchema = new schema.Entity("teams", {
    //
});

const userSchema = new schema.Entity("users", {
    memberOfTeams: [teamSchema],
});

export const loadCurrentUser = () => async (dispatch) => {
    let { data } = await get("/users/me");

    data = camelcaseKeys(data, { deep: true });
    data = normalize(data, userSchema);

    return dispatch({
        type: SET_USER_INFO,
        data,
    });
};
