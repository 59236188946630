export const SET_USER_INFO = "@postlab/currentUser/setUserInfo";

export const SET_TEAMS = "@postlab/teams/setTeams";
export const CREATE_TEAM = "@postlab/teams/createTeam";
export const DELETE_TEAM = "@postlab/teams/deleteTeam";
export const ADD_TEAM_MEMBERS = "@postlab/teams/addTeamMember";
export const REMOVE_TEAM_MEMBERS = "@postlab/teams/removeTeamMember";

export const SET_USERS = "@postlab/users/setUsers";
export const CREATE_USER = "@postlab/users/createUser";
export const UPDATE_USER = "@postlab/users/updateUser";
export const DELETE_USER = "@postlab/users/deleteUser";

export const ADD_AS_TEAM_MEMBER = "@postlab/users/addAsTeamMember";
export const REMOVE_AS_TEAM_MEMBER = "@postlab/users/removeAsTeamMember";

export const ENABLE_REMOTE_LOCKING = "@postlab/users/enableRemoteLocking";
export const DISABLE_REMOTE_LOCKING = "@postlab/users/disableRemoteLocking";
