import React from 'react';
import classnames from 'classnames';

export const Divider = ({ vertical = false }) => (
	<hr
		className={classnames('Divider', {
			'Divider--horizontal': !vertical,
			'Divider--vertical': vertical,
		})}
	/>
);
