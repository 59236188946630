import React from 'react';
import { motion } from 'framer-motion';

export const CircleDelete = props => {
	const animProps = {
		variants: {
			hover: { rotate: 90 },
		},
	};

	return (
		<motion.svg
			{...animProps}
			{...props}
			width="32"
			height="32"
			viewBox="0 0 32 32"
			fill="none"
			className="CircleDeleteIcon"
		>
			<path
				d="M19.25 12.75L12.75 19.25"
				strokeWidth="2"
				strokeLinecap="round"
			/>
			<path
				d="M19.25 19.25L12.75 12.75"
				strokeWidth="2"
				strokeLinecap="round"
			/>
			<circle
				cx="16"
				cy="16"
				r="10"
				transform="rotate(45 16 16)"
				strokeWidth="2"
			/>
		</motion.svg>
	);
};
