import React, { memo, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import { createNewTeam } from "app/store/actions/teams";

import { useTranslate } from "app/hooks";
import { useForm } from "ui/hooks/forms";

import { Modal, Panel } from "ui/components/layout";
import { PrimaryButton, SimpleButton } from "ui/components/buttons";
import { Form, TextInput, Select } from "ui/components/Form";

export const CreateNewTeam = memo(() => {
    const __ = useTranslate();

    const dispatch = useDispatch();
    const history = useHistory();

    const [error, setError] = useState();

    const formRef = useForm(async (data) => {
        try {
            await dispatch(createNewTeam(data));
            history.goBack();
        } catch (error) {
            const { errors, message } = error.response.data;
            setError(errors || message);
        }
    });

    const handleClose = () => {
        history.goBack();
    };

    // prettier-ignore
    return (
        <Modal onClose={handleClose}>
            <Panel className="CreateNewTeam">
                <header>
                    <h1>{__("teams.create-team-modal.title")}</h1>
                </header>

                <Form id='createNewTeam' ref={formRef}>
                    <TextInput label={__("teams.create-team-modal.teamname")} name="name" error={error?.name} />

                    <Select label={__("teams.create-team-modal.location")} name="location">
                        <Select.Option value="Amsterdam" selected>Amsterdam</Select.Option>
                        <Select.Option value="New York">New York</Select.Option>
                        <Select.Option value="San Francisco">San Francisco</Select.Option>
                    </Select>
                </Form>

                <footer>
                    <PrimaryButton type="submit" form="createNewTeam" className="SubmitButton">
                        {__("teams.create-team-modal.submit")}
                    </PrimaryButton>
                    
                    <SimpleButton onClick={handleClose}>
                        {__("teams.create-team-modal.cancel")}
                    </SimpleButton>
                </footer>
            </Panel>
        </Modal>
    );
});
