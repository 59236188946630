import { create } from "axios";
import is from "is_js";

const HEAD = "HEAD";
const GET = "GET";
const POST = "POST";
const PUT = "PUT";
const PATCH = "PATCH";
const DELETE = "DELETE";

const services = create({
    baseURL: process.env.API_BASE_URL,
    // timeout: process.env.API_TIMEOUT,
    headers: {
        Accept: "application/json",
        // 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
        "Content-Type": "application/json; charset=UTF-8",
    },
});

const makeRequest = async (method, path, data = {}, headers = {}) => {
    if (is.not.string(method)) {
        throw new Error(
            "Required argument method is not a String or undefined"
        );
    }

    if (is.not.string(path)) {
        throw new Error("Required argument path is not a String or undefined");
    }

    try {
        document.documentElement.classList.add("busy");
        const { data: response } = await services.request({
            url: path,
            withCredentials: true,
            method,
            headers,
            data,
        });

        return Promise.resolve(response);
    } catch (error) {
        throw error;
    } finally {
        document.documentElement.classList.remove("busy");
    }
};

export const head = (path, data, headers) =>
    makeRequest(HEAD, path, data, headers);

export const get = (path, headers) => 
	makeRequest(GET, path, null, headers);

export const post = (path, data, headers) =>
    makeRequest(POST, path, data, headers);

export const put = (path, data, headers) =>
    makeRequest(PUT, path, data, headers);

export const patch = (path, data, headers) =>
    makeRequest(PATCH, path, data, headers);

export const del = (path, data, headers) =>
    makeRequest(DELETE, path, data, headers);
