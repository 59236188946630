import React, { memo, useState } from "react";
import is from "is_js";
import { useDispatch } from "react-redux";

import { useRequest, useTranslate, useTitle } from "app/hooks";
import { resetPassword } from "app/store/actions/auth";
import { useForm } from "ui/hooks/forms";

import { PrimaryButton } from "ui/components/buttons";
import { Form, TextInput } from "ui/components/Form";
import { Alert, Text } from "ui/components/typo";
import { HeadShake } from "ui/components/animations";

export const ResetPassword = memo(() => {
    const __ = useTranslate();

    const dispatch = useDispatch();
    const requestInfo = useRequest();

    useTitle(__("reset-password.title"));

    const [error, setError] = useState();
    const [success, setSuccess] = useState(false);

    const formRef = useForm(async (data) => {
        try {
            await dispatch(resetPassword(data));
            setSuccess(true);
        } catch (error) {
            const { errors, message } = error.response.data;

            setError(errors || message);
            setSuccess(false);
        }
    });

    const title = __("reset-password.title");
    const confirmTitle = __("reset-password.confirmation.title");
    const confirmDescription = __("reset-password.confirmation.description");

    // prettier-ignore
    return (
        <section className="ResetPassword">
            {!success && 
                <>
                    <h1>{title}</h1>

                    <Form ref={formRef}>
                        <input type="hidden" name="token" value={requestInfo.token} />

                        <TextInput label={__('reset-password.password')} name="password" type="password" error={error?.password} />
                        <TextInput label={__('reset-password.password-check')} name="password_check" type="password" error={error?.password_check} />

                        {is.string(error) && <Alert>{error}</Alert>}

                        <HeadShake resetOnUpdate={true} playing={error} style={{width: '100%'}}>
                            <PrimaryButton type='submit' className='SubmitButton'>
                                {__('reset-password.submit')}
                            </PrimaryButton>
                        </HeadShake>
                    </Form>
                </>
            }

            {success && 
                <>
                    <h1>{confirmTitle}</h1>
                    <Text>{confirmDescription}</Text>
                </>
            }
        </section>
    );
});
