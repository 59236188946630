import produce from "immer";
import * as Actions from "app/constants/actions";

export const users = (currentState = {}, { type, data }) =>
    produce(currentState, (draft) => {
        if (type === Actions.SET_USERS) {
            Object.entries(data.entities.users).forEach(([id, user]) => {
                draft[id] = {
                    ...draft[id],
                    ...user,
                };
            });
        }

        if (type === Actions.SET_USER_INFO) {
            const userId = data.result;
            draft[userId] = data.entities.users[userId];
        }

        if (type === Actions.CREATE_USER) {
            const userId = data.result;
            draft[userId] = data.entities.users[userId];
        }

        if (type === Actions.ADD_AS_TEAM_MEMBER) {
            const user = draft[data.userId];
            user.memberOfTeams = [...user.memberOfTeams, ...data.teamIds];
        }

        if (type === Actions.REMOVE_AS_TEAM_MEMBER) {
            const user = draft[data.userId];

            user.memberOfTeams = user.memberOfTeams.filter(
                (teamId) => data.teamIds.includes(teamId) === false
            );
        }

        if (type === Actions.ENABLE_REMOTE_LOCKING) {
            draft[data.userId].remoteLockingEnabled = true;
        }

        if (type === Actions.DISABLE_REMOTE_LOCKING) {
            draft[data.userId].remoteLockingEnabled = false;
        }

		if (type === Actions.DELETE_USER) {
            delete draft[data.userId];
        }

        if (type === Actions.SET_TEAMS) {
            Object.entries(data.entities.users).forEach(([id, user]) => {
                if (!draft[id]) {
                    draft[id] = {
                        ...draft[id],
                        ...user,
                    };
                }
            });
        }

        if (type === Actions.DELETE_TEAM) {
            Object.values(draft).forEach((user) => {
                user.memberOfTeams = user.memberOfTeams.filter(
                    (teamId) => teamId !== data.teamId
                );
            });
        }
    });
