import React, { memo } from "react";
import { useLocation, Link } from "react-router-dom";

import { useTranslate } from "app/hooks";
import { useCurrentUser, useAllUsers } from "app/hooks/store";

import { PrimaryButton } from "ui/components/buttons";
import { Table } from "ui/components/layout";
import { UserDetails } from "../Users/UserDetails";

export const UsersIndex = memo(() => {
    const __ = useTranslate();

    const location = useLocation();
    const users = useAllUsers();
    const currentUser = useCurrentUser();

    // prettier-ignore
    return (
        <section className="UsersIndex">
            <Table fixed>
                <Table.Header>
                    <th>{__("users.member")}</th>
                    <th>{__("users.email")}</th>
                    {currentUser.allowRemoteLocking && (
                        <th>{__("users.remotelocking")}</th>
                    )}
                    <th>{__("users.teams")}</th>
                    <th></th>
                </Table.Header>

                <Table.Body>
                    {users.map((user) => (
                        <UserDetails key={user.id} id={user.id} />
                    ))}
                </Table.Body>
            </Table>

            <footer>
                <Link to={{ pathname: "/manage/users/create", state: { background: location }}}>
                    <PrimaryButton>
                        {__("users.create-user")}
                    </PrimaryButton>
                </Link>
            </footer>
        </section>
    );
});
