import React from 'react';
import { motion } from 'framer-motion';

export const Search = (props) => (
	<motion.svg
		{...props}
		width="24"
		height="24"
		viewBox="0 0 24 24"
		fill="none"
		className="SearchIcon">
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M15 11C15 13.2091 13.2091 15 11 15C8.79086 15 7 13.2091 7 11C7 8.79086 8.79086 7 11 7C13.2091 7 15 8.79086 15 11ZM14.9509 16.7793C13.8264 17.5494 12.4658 18 11 18C7.13401 18 4 14.866 4 11C4 7.13401 7.13401 4 11 4C14.866 4 18 7.13401 18 11C18 12.314 17.638 13.5434 17.0082 14.594L20.0104 17.5962C20.5962 18.182 20.5962 19.1317 20.0104 19.7175C19.4246 20.3033 18.4749 20.3033 17.8891 19.7175L14.9509 16.7793Z"
		/>
	</motion.svg>
);
