import React, { memo, useState } from "react";
import is from "is_js";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";

import { login } from "app/store/actions/auth";
import { useTranslate, useTitle } from "app/hooks";
import { useForm } from "ui/hooks/forms";

import { PrimaryButton, SimpleButton } from "ui/components/buttons";
import { Form, TextInput } from "ui/components/Form";
import { Alert } from "ui/components/typo";
import { HeadShake } from "ui/components/animations";

import Logo from "images/logo.png";

export const Login = memo(() => {
    const __ = useTranslate();
    const dispatch = useDispatch();

    useTitle(__("login.title"));

    const [error, setError] = useState();

    const formRef = useForm(async (data) => {
        try {
            await dispatch(login(data));
            window.location.href = "/manage/users";
        } catch (error) {
            const { errors, message } = error.response.data;
            setError(errors || message);
        }
    });

    // prettier-ignore
    return (
        <section className="Login">
            <img src={Logo} width="100" height="100" className="Logo" />

            <Form ref={formRef}>
                <TextInput label={__('login.username')} name="username" error={error?.username} />
                <TextInput label={__('login.password')} name="password" type="password" error={error?.password} />

                {is.string(error) && <Alert>{error}</Alert>}

                <HeadShake resetOnUpdate={true} playing={error}>
                    <PrimaryButton type='submit' className='SubmitButton'>
                        {__('login.submit')}
                    </PrimaryButton>
                </HeadShake>

                <Link to="/password">
                    <SimpleButton className='PasswordButton'>
                        {__('login.forget-password')}
                    </SimpleButton>
                </Link>
            </Form>
        </section>
    );
});
