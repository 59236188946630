import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { loadUsers } from "app/store/actions/users";
import { getAllUsers } from "app/store/selectors/users";

export const useAllUsers = () => {
    const dispatch = useDispatch();
    const users = useSelector((state) => getAllUsers(state));

    useEffect(() => {
        dispatch(loadUsers());
    }, []);

    return users;
};
