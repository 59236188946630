import React, { memo, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";

import { addTeamMembers } from "app/store/actions/teams";
import { useTranslate } from "app/hooks";
import { useForm } from "ui/hooks/forms";
import { useAllUsers, useTeam } from "app/hooks/store";

import { Modal, Panel } from "ui/components/layout";
import { PrimaryButton, SimpleButton } from "ui/components/buttons";
import { Form, MultiSelect } from "ui/components/Form";

export const AddTeamMembers = memo(() => {
    const __ = useTranslate();

    const dispatch = useDispatch();
    const history = useHistory();
    const params = useParams();

    const [error, setError] = useState();

    const teamInfo = useTeam(params.teamId);
    const users = useAllUsers()
        .filter((user) => !teamInfo.teamMembers.includes(user.id))
        .filter((user) => user.userStatus !== "pending");

    const formRef = useForm(async (data) => {
        try {
            await dispatch(addTeamMembers(data));
            history.goBack();
        } catch (error) {
            const { errors, message } = error.response.data;
            setError(errors || message);
        }
    });

    const handleClose = () => {
        history.goBack();
    };

    // prettier-ignore
    return (
        <Modal onClose={handleClose}>
            <Panel className="AddTeamMembers">
                <header>
                    <h1>{__("teams.add-members.title")}</h1>
                </header>

                <Form id='addTeamMembers' ref={formRef}>
                    <input type="hidden" name="teamId" value={params.teamId} />

                    <MultiSelect name='users'>
                        {users.map(({ id, fullName }, index) => (
                            <MultiSelect.Option key={id} value={id} label={fullName} error={error?.teams} />
                        ))}
                    </MultiSelect>
                </Form>

                <footer>
                    <PrimaryButton type="submit" form="addTeamMembers" className="SubmitButton">
                        {__("teams.add-members.submit")}
                    </PrimaryButton>

                    <SimpleButton onClick={handleClose}>
                        {__("teams.add-members.cancel")}
                    </SimpleButton>
                </footer>
            </Panel>
        </Modal>
    );
});
