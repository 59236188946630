import { post } from "app/services";
import { ENABLE_REMOTE_LOCKING } from "app/constants/actions";

export const enableRemoteLocking = (userId) => async (dispatch) => {
    await post(`/users/${userId}/remotelocking`);

    dispatch({
        type: ENABLE_REMOTE_LOCKING,
        data: {
            userId,
        },
    });
};
