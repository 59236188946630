import React, { memo } from "react";
import { useDispatch } from "react-redux";

import { useCurrentUser } from "app/hooks/store";
import { logout } from "app/store/actions/auth";

import { Navigation } from "./Header/Navigation";
import { SignoutButton } from "./Header/SignoutButton";

import Logo from "images/logo.png";

export const Header = memo(() => {
    const dispatch = useDispatch();
    const currentUser = useCurrentUser();

    const handleSignout = () => {
        dispatch(logout());
    };

    return (
        <header className="Header">
            <img src={Logo} width="48" height="48" className="Logo" />

            <Navigation />

            <SignoutButton onClick={handleSignout}>
                {currentUser.fullName}
            </SignoutButton>
        </header>
    );
});
