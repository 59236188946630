import React from 'react';
import classnames from 'classnames';
import styled from 'styled-components';

export const HBox = ({ children, className, ...remainingProps }) => (
	<StyledHBox {...remainingProps} className={classnames('HBox', className)}>
		{children}
	</StyledHBox>
);

const StyledHBox = styled.div`
	display: flex;
	flex-direction: row;
	align-items: baseline;
`;
