import React, { memo, useState } from "react";
import is from "is_js";
import { useDispatch } from "react-redux";

import { useTranslate, useTitle } from "app/hooks";
import { requestPassword } from "app/store/actions/auth";
import { useForm } from "ui/hooks/forms";

import { PrimaryButton } from "ui/components/buttons";
import { Form, TextInput } from "ui/components/Form";
import { Alert, Text } from "ui/components/typo";
import { HeadShake } from "ui/components/animations";

export const RequestPassword = memo(() => {
    const __ = useTranslate();
    const dispatch = useDispatch();

    useTitle(__("request-password.title"));

    const [error, setError] = useState();
    const [success, setSuccess] = useState(false);

    const formRef = useForm(async (data) => {
        try {
            await dispatch(requestPassword(data));
            setSuccess(true);
        } catch (error) {
            const { errors, message } = error.response.data;

            setError(errors || message);
            setSuccess(false);
        }
    });

    const confirmation = __("request-password.confirmation");

    // prettier-ignore
    return (
        <section className="RequestPassword">
            <h1>{__('request-password.title')}</h1>

            {!success && 
                <Form ref={formRef}>
                    <TextInput label={__('request-password.username')} name="username" error={error?.username} />

                    {is.string(error) && <Alert>{error}</Alert>}

                    <HeadShake resetOnUpdate={true} playing={error} style={{width: '100%'}}>
                        <PrimaryButton type='submit' className='SubmitButton'>
                            {__('request-password.submit')}
                        </PrimaryButton>
                    </HeadShake>
                </Form>
            }

            {success && (
                <Text>{confirmation}</Text>
            )}
        </section>
    );
});
