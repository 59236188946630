import { useEffect } from 'react';
import { noop } from '../helpers';

export const useEscKey = (handler = noop) => {
	const keyDownHandler = ({ keyCode }) => {
		if (keyCode === 27) {
			handler();
		}
	};

	useEffect(() => {
		window.addEventListener('keydown', keyDownHandler);

		return () => {
			window.removeEventListener('keydown', keyDownHandler);
		};
	});
};
