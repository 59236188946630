import React, { memo } from "react";
import { NavLink } from "react-router-dom";
import { useTranslate } from "app/hooks";

export const Navigation = memo(() => {
    const __ = useTranslate();

    return (
        <nav className="Navigation">
            <ol>
                <li>
                    <NavLink to="/manage/users">
                        {__("navigation.users")}
                    </NavLink>
                </li>
                <li>
                    <NavLink to="/manage/teams">
                        {__("navigation.teams")}
                    </NavLink>
                </li>
            </ol>
        </nav>
    );
});
