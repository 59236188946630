import camelcaseKeys from "camelcase-keys";
import { normalize, schema } from "normalizr";

import { get } from "app/services";
import { SET_USERS } from "app/constants/actions";

const teamSchema = new schema.Entity("teams", {
    //
});

const userSchema = new schema.Entity("users", {
    memberOfTeams: [teamSchema],
});

export const loadUsers = () => async (dispatch) => {
    let { data } = await get("/users");

    data = camelcaseKeys(data, { deep: true });
    data = normalize(data, [userSchema]);

    dispatch({
        type: SET_USERS,
        data,
    });
};
