import React from 'react';
import { motion } from 'framer-motion';

export const ChevronDown = props => (
	<motion.svg
		{...props}
		width="24"
		height="24"
		fill="none"
		viewBox="0 0 24 24"
		className="ChevronDownIcon"
	>
		<path
			d="M6 10L12 16L18 10"
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</motion.svg>
);
