import { useState, useEffect } from 'react';

export const useIsScrolling = () => {
	const [isScrolling, setIsScrolling] = useState(false);
	let scrollId = 0;

	const handleScrollStart = () => {
		clearTimeout(scrollId);
		setIsScrolling(true);

		scrollId = setTimeout(() => {
			setIsScrolling(false);
		}, 66);
	};

	useEffect(() => {
		window.addEventListener('scroll', handleScrollStart, { passive: true });

		return () => {
			window.removeEventListener('scroll', handleScrollStart);
		};
	}, []);

	return isScrolling;
};
