import React from 'react';
import classnames from 'classnames';
import { motion } from 'framer-motion';
import { Spinner } from '../Spinner';

export const PrimaryButton = ({ children, className, ...remainingProps }) => {
	const buttonProps = {
		type: 'button',
		whileHover: 'hover',
		className: classnames('PrimaryButton', className),
		...remainingProps,
	};

	return <motion.button {...buttonProps}>{children}</motion.button>;
};
