import React, { Children } from "react";
import classnames from "classnames";

export const Collection = ({ children, className, ...remainingProps }) => {
    return (
        <ol className={classnames("Collection", className)} {...remainingProps}>
            {Children.map(children, (child) => (
                <li>{child}</li>
            ))}
        </ol>
    );
};
