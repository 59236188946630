import React, { memo } from "react";
import classNames from "classnames";
import { useDispatch } from "react-redux";
import { motion } from "framer-motion";

import { removeTeamMember } from "app/store/actions/teams";
import { useConfirm, useTranslate } from "app/hooks";
import { useCurrentUser, useUserInfo } from "app/hooks/store";
import { Delete } from "ui/components/icons";

export const TeamMember = memo(
    ({ teamId, userId, disabled, ...remainingProps }) => {
        const __ = useTranslate();

        const confirm = useConfirm();
        const dispatch = useDispatch();

        const currentUser = useCurrentUser();
        const userInfo = useUserInfo(userId);

        const confirmDelete = __("teams.remove-member", {
            user: userInfo.fullName,
        });

        const handleRemove = async () => {
            const confirmed = await confirm({ description: confirmDelete });
            if (confirmed) {
                dispatch(removeTeamMember({ teamId, userId }));
            }
        };

        const isCurrentUser = currentUser.id === userId;
        const pending = userInfo.userStatus === "pending";

        const buttonProps = {
            type: "button",
            whileHover: "hover",
            onClick: handleRemove,
            className: classNames("TeamMember", {
                "TeamMember--pending": pending,
                "TeamMember--isCurrentUser": isCurrentUser,
                "TeamMember--disabled": disabled,
            }),
            disabled,
            ...remainingProps,
        };

        // prettier-ignore
        return (
            <motion.button {...buttonProps}>
                {userInfo.isCurrentUser ? `${userInfo.fullName} (You)` : userInfo.fullName}
                {!disabled && !isCurrentUser && <Delete size="small" />}
            </motion.button>
        );
    }
);
